import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import SharedProviders_0_0 from './extensions/d72843451919cab8c42928a0999fd281296f5c7936923d51f886711e15de22f4/navBarModifier';
import SharedProviders_1_0 from './extensions/c4cde75d5d13dea9461cc07fb3440b568ee013718d364ff41264dbe22ea667b3/providers';
import SharedProviders_2_0 from './extensions/software-houses/providers';


@NgModule({
    imports: [CommonModule, ],
    providers: [...SharedProviders_0_0, ...SharedProviders_1_0, ...SharedProviders_2_0],
})
export class SharedExtensionsModule {}
